import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout.js';
import TitleBar from '../components/title-bar.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  faExternalLinkSquareAlt
} from '@fortawesome/free-solid-svg-icons';

export default ({ data}) => {
  let comedy = data.allMarkdownRemark.edges;
  return (
    <Layout name="comedy">
      <ul className='comedy-list'>
        {comedy.map(({node}) => {
          let comedyType = node.frontmatter.type;
          if (comedyType === 'link') {
            return <Link link={node.frontmatter}/>;
          }
          if (comedyType === 'picture'){
            return <Picture picture={node.frontmatter}/>;
          }
          if (comedyType === 'video') {
            return <Video video={node.frontmatter}/>;
          } 
          return <p>{node.frontmatter.title}</p>;
        })}
      </ul>
    </Layout>
  );
}

const Link = ({link}) => {
  return (
    <li className='link'>
      <a href={link.url} target="blank" rel="noreferrer noopen">
        <div className='link-title'>
          <p>{link.title}</p>
        </div>
        <figure>
          <img src={link.image} alt={link.title}/>
          <figcaption>
            {link.caption}
          </figcaption>
        </figure>
    <FontAwesomeIcon icon={faExternalLinkSquareAlt} className='link-icon'/>
      </a>
    </li>
  );
};

const Picture = ({picture}) => {
  return (
    <li className="picture">
      <figure>
        <img src={picture.image} alt={picture.title}/>
        <figcaption>{picture.caption}</figcaption>
      </figure>
    </li>
  );
};

const Video = ({video}) => {
  let embedLink = video.url.replace("youtube.com", "youtube.com/embed").replace("watch?v=","");
  return (
    <li className="video">
      <figure>
        <iframe src={embedLink} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <figcaption>{video.caption}</figcaption>
      </figure>
    </li>
  )

   
}



export const query = graphql`
query MyComedy {
  allMarkdownRemark(filter: {fields: {section: {eq: "comedy"}}}) {
    edges {
      node {
        frontmatter {
          title
          url
          image
          caption
          type
        }
      }
    }
  }
}
`

